import { Link } from "gatsby"
import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>
      Ops...
      <br/>
      <br/>
      If you are sure this page should exist, please <Link to="/contact">get in touch!</Link>
    </p>
  </Layout>
)

export default NotFoundPage
